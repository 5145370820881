var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto" },
    [
      _c("h3", [_vm._v("sample checkbox group")]),
      _c(
        "ValidationObserver",
        { ref: "observer" },
        [
          _c("FlCheckboxGroup", {
            attrs: {
              label: "resolution",
              options: _vm.movieResolution,
              rules: { required: true, min: 1 },
            },
            model: {
              value: _vm.resolutions,
              callback: function ($$v) {
                _vm.resolutions = $$v
              },
              expression: "resolutions",
            },
          }),
        ],
        1
      ),
      _c("button", { on: { click: _vm.validate } }, [_vm._v("validate")]),
      _c("h3", [_vm._v("sample radio group")]),
      _c(
        "ValidationObserver",
        { ref: "observer2" },
        [
          _c("FlRadioGroup", {
            attrs: {
              label: "resolution",
              options: _vm.movieResolution,
              rules: { required: true, oneOf: [1, 2] },
            },
            model: {
              value: _vm.resolution,
              callback: function ($$v) {
                _vm.resolution = $$v
              },
              expression: "resolution",
            },
          }),
        ],
        1
      ),
      _c("button", { on: { click: _vm.validate2 } }, [_vm._v("validate")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }